class showStep {
  constructor() {
    this.count = 0
    this.data = []
  }
}

//10 - white
//20 - purple1
//30 - purple2
//40 - blue1
//50 - blue2
//60 - green
//70 - yellow1
//80 - yellow2


export function buildShow(){
  let arr = []
  arr[0] = buildShow1()
  arr[1] = buildShow2()
  arr[2] = buildShow3()
  arr[3] = buildShow4()
  arr[4] = buildShow5()
  arr[5] = buildShow6()
  return arr
}

function buildShow6(){
  let arr = []
  let lss = new showStep()
  lss.count = 1
  lss.data = [1, 1, 1, 1]
  lss.colors = [10, 10, 60, 60]
  arr[0] = lss
  lss = new showStep()
  lss.count = 1
  lss.data = [-1, -1, 1, 1]
  lss.colors = [60, 60, 10, 10]
  arr[1] = lss
  lss = new showStep()
  lss.count = 1
  lss.data = [1, 1, -1, -1]
  lss.colors = [60, 60, 10, 10]
  arr[2] = lss
  lss = new showStep()
  lss.count = 1
  lss.data = [-1, -1, 1, 1]
  lss.colors = [60, 60, 10, 10]
  arr[3] = lss
  return arr
}

function buildShow5(){
  let arr = []
  let lss = new showStep()
  lss.count = 1
  lss.data = [1, 1, 1, 1]
  lss.colors = [10, 10, 50, 50]
  arr[0] = lss
  lss = new showStep()
  lss.count = 1
  lss.data = [-1, -1, 1, 1]
  lss.colors = [10, 10, 50, 50]
  arr[1] = lss
  lss = new showStep()
  lss.count = 1
  lss.data = [-1, -1, -1, -1]
  lss.colors = [10, 10, 50, 50]
  arr[2] = lss
  lss = new showStep()
  lss.count = 1
  lss.data = [1, 1, 1, 1]
  lss.colors = [10, 10, 50, 50]
  arr[3] = lss
  return arr
}

function buildShow4(){
  let arr = []
  let lss = new showStep()
  lss.count = 1
  lss.data = [-1, -1, -1, 1]
  lss.colors = [50, 50, 40, 40]
  arr[0] = lss
  lss = new showStep()
  lss.count = 1
  lss.data = [-1, 1, -1, -1]
  lss.colors = [50, 50, 40, 40]
  arr[1] = lss
  lss = new showStep()
  lss.count = 1
  lss.data = [1, -1, -1, -1]
  lss.colors = [50, 50, 40, 40]
  arr[2] = lss
  lss = new showStep()
  lss.count = 1
  lss.data = [-1, -1, 1, -1]
  lss.colors = [50, 50, 40, 40]
  arr[3] = lss
  return arr
}

function buildShow3(){
  let arr = []
  let lss = new showStep()
  lss.count = 1
  lss.data = [-1, -1, 1, -1]
  lss.colors = [30, 30, 20, 20]
  arr[0] = lss
  lss = new showStep()
  lss.count = 1
  lss.data = [1, -1, -1, -1]
  lss.colors = [30, 30, 20, 20]
  arr[1] = lss
  lss = new showStep()
  lss.count = 1
  lss.data = [-1, 1, -1, -1]
  lss.colors = [30, 30, 20, 20]
  arr[2] = lss
  lss = new showStep()
  lss.count = 1
  lss.data = [-1, -1, -1, 1]
  lss.colors = [30, 30, 20, 20]
  arr[3] = lss
  return arr
}

function buildShow2(){
  let arr = []
  let lss = new showStep()
  lss.count = 2
  lss.data = [1, 1, 1, 1]
  lss.colors = [10, 10, 30, 30]
  arr[0] = lss

  lss = new showStep()
  lss.count = 2
  lss.data = [1, 1, 1, 1]
  lss.colors = [20, 20, 40, 40]
  arr[1] = lss
  return arr
}

function buildShow1(){
  let arr = []
  let lss = new showStep()
  lss.count = 2
  lss.data = [1, 1, -1, -1]
  lss.colors = [80, 80, 30, 50]
  arr[0] = lss

  lss = new showStep()
  lss.count = 2
  lss.data = [-1, -1, 1, 1]
  lss.colors = [50, 30, 80, 80]
  arr[1] = lss
  return arr
}
