const SvgStop = ({onClick}) => {

  return (  
    <svg className="svg-arrow hov z-80" onClick={onClick} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <circle cx="50" cy="50" r="47" strokeWidth="5" fill="none" />  
      <rect x="30" y="30" width="40" height="40" stroke="none"/>             
    </svg>
  )
}

export default SvgStop