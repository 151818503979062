import CanvBorder from "./CanvBorder"

const PageTitle = ({title, subtitle, logo, zndx="100", uid="1", hrefLink=""}) => {
  //let clrs = ["#384759", "#566374", "#8A96A4", "#BCC5D2", "#566374"] // original grey        
  //let stps = [0.0, 0.3, 0.45, 0.65, 1.0] // original grey
  //let clrs = ["#808FA1", "#A2AFC0", "#CFE1F8", "#FFFFFF", "#345277"] // light grey  
  //let stps = [0.0, 0.3, 0.45, 0.6, 1.0] // light grey
  let clrs = ["#332D31", "#9B7A94", "#CFE1F8", "#cab3c6", "#345277"] // pinkish hue    
  let stps = [0.0, 0.2, 0.45, 0.65, 1.0] // pinkish hue
  let sizerid = uid + "-cbc"

  return (

    <article data-desc="is-container" id={sizerid} className="parent-article flex-col ass"
        style={{position:"relative", border:"none", background:"none", zIndex:zndx, textAlign:"center"}}>      
      {subtitle ? (
        <>
        <h3 style={{padding:"1rem", color:("var(--icon-border-grey)"), marginBottom:"0"}}>
          {title}
        </h3>
        <h4 style={{padding:"1rem", color:("var(--icon-border-grey)"), marginTop:"0", paddingTop:"0"}}>
          {subtitle}
        </h4>
        </>
        ) : (
          <div className="flex-row">
            <img style={{display:"flex", height:"2.3rem"}} src={logo} alt=""/>
            {hrefLink ? (          
            <a style={{display:"flex", zIndex:"80", textDecoration:"none"}} href={hrefLink}>
              <h3 style={{padding:"1rem", color:("var(--icon-border-grey)")}}>{title}</h3>
            </a>        
            ) : (
              <h3 style={{padding:"1rem", color:("var(--icon-border-grey)")}}>{title}</h3>              
            )}  
          </div>
        )
      }
      <div style={{position:"absolute", left:"0", top:"0"}}>
        <CanvBorder data-desc="is-canvas" sizer={sizerid} type="round-rect" radius="20" thick="10" colors={clrs} stops={stps}/>       
      </div>
     </article>      
  )
}

export default PageTitle