import { cl, pub } from '../winglob'
import { useEffect, useContext } from "react"
//import { useNavigate } from "react-router-dom"

import { ModalContext } from "../Layout"
import UfoShow from '../comp/ufoshow/UfoShow'
import Carousel from '../comp/Carousel'
import PageTitle from "../comp/PageTitle"

import reactLogo from '../img/credit-icons/react.svg'
import viteLogo from '../img/credit-icons/vite.svg'

const GlamPage = () => {
  cl("render: GlamPage")
  //const navigate = useNavigate()

  const { checkModal } = useContext(ModalContext);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <section className="page-section" data-desc="is-sizer" id="page-sizer" style={{ position: "relative" }}>

        <UfoShow />

        <PageTitle title="React Demos" logo={reactLogo} uid="1" />

        <article id="react-demos" className="parent-article flex-row wrap ass" style={{ alignItems: "stretch", padding: "0" }}>

          <article className="child-article flex-col col-space" style={{ width: "100%", flex: "1", minWidth: "280px" }}>
            <div className="flex-col w-100">

              <a href="https://www.kevmetz.com/glam" style={{ display: "flex", zIndex: "80", textDecorationColor: ("var(--icon-border-grey)") }}>
                <h3 style={{ color: ("var(--icon-border-grey)") }}>Celebrity</h3>
              </a>

              <Carousel msg="glam" key="glam" dly={0}>
                <div className="carousel-section flex-row">
                  <a href="https://www.kevmetz.com/glam/1" style={{ display: "flex" }}>
                    <img className="carousel-image" src={pub() + "/carousel/carly1.png"} onLoad={() => checkModal()} alt="" />
                  </a>
                </div>
                <div className="carousel-section flex-row">
                  <a href="https://www.kevmetz.com/glam/2" style={{ display: "flex" }}>
                    <img className="carousel-image" src={pub() + "/carousel/madonna1.png"} onLoad={() => checkModal()} alt="" />
                  </a>
                </div>
                <div className="carousel-section flex-row">
                  <a href="https://www.kevmetz.com/glam/3" style={{ display: "flex" }}>
                    <img className="carousel-image" src={pub() + "/carousel/50cent1.png"} onLoad={() => checkModal()} alt="" />
                  </a>
                </div>
              </Carousel>
            </div>
          </article>

          <article className="child-article flex-col col-space" style={{ width: "100%", flex: "1", minWidth: "280px" }}>
            <div className="flex-col w-100">

              <a href="https://www.kevmetz.com/various" style={{ display: "flex", zIndex: "80", textDecorationColor: ("var(--icon-border-grey)") }}>
                <h3 style={{ color: ("var(--icon-border-grey)") }}>Variety</h3>
              </a>

              <Carousel msg="various" key="various" dly={250}>
                <div className="carousel-section flex-row">
                  <a href="https://www.kevmetz.com/super-gloomy" style={{ display: "flex" }}>
                    <img className="carousel-image" src={pub() + "/carousel/gloomy3.png"} onLoad={() => checkModal()} alt="" />
                  </a>
                </div>
                <div className="carousel-section flex-row">
                  <a href="https://www.kevmetz.com/weather" style={{ display: "flex" }}>
                    <img className="carousel-image" src={pub() + "/carousel/weather1.png"} onLoad={() => checkModal()} alt="" />
                  </a>
                </div>
                <div className="carousel-section flex-row">
                  <a href="https://www.kevmetz.com/killer-kones" style={{ display: "flex" }}>
                    <img className="carousel-image" src={pub() + "/carousel/icecream1.png"} onLoad={() => checkModal()} alt="" />
                  </a>
                </div>
                <div className="carousel-section flex-row">
                  <a href="https://www.kevmetz.com/whsim" style={{ display: "flex" }}>
                    <img className="carousel-image" src={pub() + "/carousel/whsim2.png"} onLoad={() => checkModal()} alt="" />
                  </a>
                </div>
              </Carousel>
            </div>
          </article>

        </article>

        <PageTitle title="Vanilla JS Demos" logo={viteLogo} uid="2" />

        <article id="vanilla-js-demos" className="parent-article flex-row wrap ass" style={{ alignItems: "stretch", padding: "0" }}>

          <article className="child-article flex-col col-space" style={{ width: "100%", flex: "1", minWidth: "280px" }}>
            <div className="flex-col w-100">

              <a href="https://www.kevmetz.com/glam/200" style={{ display: "flex", zIndex: "80", textDecorationColor: ("var(--icon-border-grey)") }}>
                <h3 style={{ color: ("var(--icon-border-grey)") }}>Celebrity</h3>
              </a>

              <Carousel msg="glam" key="js-glam" dly={500}>
                <div className="carousel-section flex-row">
                  <a href="https://www.kevmetz.com/glam/201" style={{ display: "flex" }}>
                    <img className="carousel-image" src={pub() + "/carousel/carly1.png"} onLoad={() => checkModal()} alt="" />
                  </a>
                </div>
                <div className="carousel-section flex-row">
                  <a href="https://www.kevmetz.com/glam/202" style={{ display: "flex" }}>
                    <img className="carousel-image" src={pub() + "/carousel/madonna1.png"} onLoad={() => checkModal()} alt="" />
                  </a>
                </div>
                <div className="carousel-section flex-row">
                  <a href="https://www.kevmetz.com/glam/203" style={{ display: "flex" }}>
                    <img className="carousel-image" src={pub() + "/carousel/50cent1.png"} onLoad={() => checkModal()} alt="" />
                  </a>
                </div>
              </Carousel>
            </div>
          </article>


        </article>

      </section >
    </>
  )
}

export default GlamPage