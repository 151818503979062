//goto 
export function pub() {
  return process.env.PUBLIC_URL
}

export function gebi(x) {
  return document.getElementById(x)
}

export function bound(e) {
  return e.getBoundingClientRect()
}
export function gebound(x) {
  return document.getElementById(x).getBoundingClientRect()
}

export function gecc(x) {
  return gebi(x).getContext('2d') 
}

export function cl(a, b=null){
  if(!b) b = new Date().getTime()
  console.log("CL - " + a, b)
}

export function irnd(i) {
  return(0 | Math.random()*i)
}
