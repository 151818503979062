
const HitCounter = () => {
  return (
    <div id="sfc27m611gpe26k99w5xfu6xpnlw6gfq7hq" style={{ visibility: "hidden" }}>
      <script type="text/javascript" src="https://counter3.optistats.ovh/private/counter.js?c=27m611gpe26k99w5xfu6xpnlw6gfq7hq&down=async" async></script>
      <a href="https://www.freecounterstat.com/geozoom.php?c=27m611gpe26k99w5xfu6xpnlw6gfq7hq&base=counter3">
        <img width="100px" src="https://counter3.optistats.ovh/private/freecounterstat.php?c=27m611gpe26k99w5xfu6xpnlw6gfq7hq" border="0" alt="" />
      </a>
    </div>
  )
}

export default HitCounter