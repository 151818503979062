import { cl, bound } from '../winglob'
import { useState, useEffect, useCallback, useRef } from 'react'

import SvgArrow from './SvgArrow'


const Carousel = ({ children, msg, dly = 0 }) => {
  cl("render: Carousel")

  const [dotIndex, setDotIndex] = useState(0)
  const [carArray, setCarArray] = useState()
  const running = useRef(false)
  const container = useRef()
  const timerRef = useRef()
  const animRef = useRef(null)
  const carIndex = useRef(0)
  const swipePos = useRef([0, 0])

  const startAnim = useCallback((mode, modeIndex) => {
    let cnt = 0
    let th = null
    let cnt_inc = 1
    let cnt_lim = 10
    let car1, car2
    if (running.current) return

    let w
    if (mode === 0 || mode === 1)
      w = bound(container.current).width
    else if (mode === 2 || mode === 3 || mode === 4)
      w = bound(container.current).height
    let cnt_size = w / cnt_lim

    car1 = carArray[carIndex.current]
    if (mode === 0) { // left arrow - from left
      if (carIndex.current === 0)
        car2 = carArray[carArray.length - 1]
      else car2 = carArray[carIndex.current - 1]
      car2.style.left = String(-w) + "px"
      car2.style.top = "0px"
    }
    else if (mode === 1) { // right arrow - from right
      if (carIndex.current === carArray.length - 1)
        car2 = carArray[0]
      else car2 = carArray[carIndex.current + 1]
      car2.style.left = String(w) + "px"
      car2.style.top = "0px"
    }
    else if (mode === 2) { // dot select - from top
      car2 = carArray[modeIndex]
      car2.style.left = "0px"
      car2.style.top = String(-w) + "px"
    }
    else if (mode === 3) { // dot select - fade
      car2 = carArray[modeIndex]

      car2.style.left = "0px"
      car2.style.top = "0px"
      car2.style.opacity = "0"
    }
    else if (mode === 4) { // timer select - fade
      if (carIndex.current === carArray.length - 1)
        car2 = carArray[0]
      else car2 = carArray[carIndex.current + 1]

      car2.style.left = "0px"
      car2.style.top = "0px"
      car2.style.opacity = "0"
    }

    function doLoop() {
      cnt += cnt_inc;
      if (cnt <= cnt_lim) {
        //clearTimeout(th)
        //th = setTimeout(() => doLoop(), 20)
        clearTimeout(animRef.current)
        animRef.current = setTimeout(() => doLoop(), 20)

        if (mode === 0) {
          car1.style.left = (cnt * cnt_size) + "px"
          car2.style.left = ((cnt * cnt_size) - w) + "px"
        }
        else if (mode === 1) {
          car1.style.left = -(cnt * cnt_size) + "px"
          car2.style.left = (w - (cnt * cnt_size)) + "px"
        }
        else if (mode === 2) {
          car1.style.top = (cnt * cnt_size) + "px"
          car2.style.top = (-w + (cnt * cnt_size)) + "px"
        }
        else if (mode === 3 || mode === 4) {
          car1.style.opacity = 1 - ((cnt * cnt_size) / w)
          car2.style.opacity = (cnt * cnt_size) / w
        }
      }
      else {
        //clearTimeout(th)
        clearTimeout(animRef.current)
        running.current = false
        car1.style.left = "-10000px"
        car1.style.top = "0px"
        car2.style.left = "0px"
        car2.style.top = "0px"
        car1.style.opacity = "100"
        car2.style.opacity = "100"
        car1 = car2
        car2 = null
        let ndx = carIndex.current
        if (mode === 0) {
          ndx--
          if (ndx === -1) ndx = carArray.length - 1
        }
        else if (mode === 1) {
          ndx++
          if (ndx === carArray.length) ndx = 0
        }
        else if (mode === 2) {
          ndx = modeIndex
        }
        else if (mode === 3) {
          ndx = modeIndex
          console.log(carIndex)
        }
        else if (mode === 4) {
          ndx++
          if (ndx === carArray.length) ndx = 0
        }
        carIndex.current = ndx
        setDotIndex(ndx)
      }
    }
    running.current = true
    doLoop()
  }, [carArray, carIndex])//startAnim

  const startShow = useCallback(() => {

    function moveSlide() {
      startAnim(4)
      clearTimeout(timerRef.current)
      timerRef.current = setTimeout(() => { moveSlide() }, 5000)
    }

    clearTimeout(timerRef.current)
    timerRef.current = setTimeout(() => { moveSlide() }, (10000 + dly))

  }, [startAnim])//

  const stopShow = useCallback((msg) => {
    clearTimeout(timerRef.current)
  }, [])//

  useEffect(() => {
    setCarArray(container.current.childNodes)
    container.current.childNodes[0].style.left = 0
  }, [])

  useEffect(() => {

    let options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.0,
    };

    let observer = new IntersectionObserver((entries) => {
      if (entries[0].intersectionRatio <= 0)
        stopShow(msg)
      else startShow(msg)

    }, options);
    observer.observe(container.current);

    return () => {
      observer.disconnect();
      clearTimeout(timerRef.current)
    }

  }, [startShow, stopShow, msg])


  return (
    <>
      <div ref={container} className="carousel-container flex-row z-80" onTouchStart={(e) => swipeStart(e)} onTouchMove={(e) => swipeMove(e)}>
        {children}
      </div>
      <article className="flex-row z-80">
        <SvgArrow onClick={() => leftClick()} flip={true} />
        {carArray && Array.from(carArray).map((_x, ndx) => {
          return (
            <div onClick={() => dotClick(ndx)} className="carousel-dot hov" key={ndx}>
              <div className="carousel-dot-inner" style={dotIndex === ndx ? { background: "var(--selected-white)" } : null}></div>
            </div>
          )
        })}
        < SvgArrow onClick={() => rightClick()} />
      </article>
    </>
  )

  //handlers

  function swipeStart(e) {
    swipePos.current[0] = e.touches[0].clientX;
    swipePos.current[1] = e.touches[0].clientY;
  }

  function swipeMove(e) {
    let dx = e.touches[0].clientX - swipePos.current[0]
    //let dy = e.touches[0].clientY - swipePos.current[1];

    if (dx < 0) rightClick()
    else if (dx > 0) leftClick()

    swipePos.current[0] = e.touches[0].clientX;
    swipePos.current[1] = e.touches[0].clientY;
  }

  function rightClick() {
    startAnim(1)
    startShow()
  }

  function leftClick() {
    startAnim(0)
    startShow()
  }

  function dotClick(ndx) {
    if (ndx !== dotIndex) {
      startAnim(2, ndx)
      startShow()
    }
  }


}

export default Carousel