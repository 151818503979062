//k3cam.js - 3D camera for three.js plus keyboard, mouse and 2D overlay using

import * as THREE from 'three'

export function k3cam() //k3cam object
{
	//properties
	//this.camera = new THREE.PerspectiveCamera( 20, window.innerWidth/window.innerHeight, 0.1, 5000 );
	//this.camera = new THREE.PerspectiveCamera( 40, window.innerWidth/window.innerHeight, 0.1, 5000 );
	//this.camera = new THREE.PerspectiveCamera( 60, window.innerWidth/window.innerHeight, 0.1, 5000 );
	//this.camera = new THREE.PerspectiveCamera( 90, window.innerWidth/window.innerHeight, 0.1, 5000 );
	//this.camera = new THREE.PerspectiveCamera( 120, window.innerWidth/window.innerHeight, 0.1, 5000 );
	this.camera = new THREE.PerspectiveCamera( 150, window.innerWidth/window.innerHeight, 0.1, 5000 );
	//this.camera = new THREE.PerspectiveCamera( 170, window.innerWidth/window.innerHeight, 0.1, 5000 );
	
	this.camera.position.x = 0;
	this.camera.position.y = 0;
	this.camera.position.z = 10;
	this.camera.rotation.x = 0;
	this.camera.rotation.y = 0;
	this.camera.rotation.z = 0;
	this.dirXZ = 180.0;
	this.dirYZ = 0.0;
	this.lookPoint = new THREE.Vector3();
	this.lookPoint.x = 0;
	this.lookPoint.y = 0;
	this.lookPoint.z = 0;
	this.defLookDist = 50.0; // default look distance
	this.camera.lookAt( this.lookPoint );

	this.curMouseX = 0;
	this.curMouseY = 0;
	this.mouseDiffX = 0;
	this.mouseDiffY = 0;
	this.curSwipeX_1 = 0;
	this.curSwipeY_1 = 0;
	this.swipeDiffX_1 = 0;
	this.swipeDiffY_1 = 0;
	this.curSwipeX_2 = 0;
	this.curSwipeY_2 = 0;
	this.swipeDiffX_2 = 0;
	this.swipeDiffY_2 = 0;
	this.curSwipeX_3 = 0;
	this.curSwipeY_3 = 0;
	this.swipeDiffX_3 = 0;
	this.swipeDiffY_3 = 0;
	this.mouseBtn0 = false;
	this.mouseBtn1 = false;
	this.mouseBtn2 = false;
	this.posLocked = false;
	this.viewLocked = false;
	this.autofly = new k3camAutofly(); //k3autofly object
	this.lights = new k3lights(); //k3lights object
	
	//methods
	this.mouseDown = k3cam_mouseDown;
	this.mouseUp = k3cam_mouseUp;
	this.mouseMove = k3cam_mouseMove;
	this.keyDown = k3cam_keyDown;
	this.swipeDown1 = k3cam_swipeDown_1;
	this.swipeMove1 = k3cam_swipeMove_1;
	this.swipeDown2 = k3cam_swipeDown_2;
	this.swipeMove2 = k3cam_swipeMove_2;
	this.swipeDown3 = k3cam_swipeDown_3;
	this.swipeMove3 = k3cam_swipeMove_3;
	this.setDir = k3cam_setDir;
	this.updateDir = k3cam_updateDir;
	this.addPos = k3cam_addPos;
	this.makeSkybox = k3cam_makeSkybox;
	this.d2r = function( d ){ return d/180.0*Math.PI; } // degrees to radians
	this.r2d = function( r ){ return r*180.0/Math.PI; } // radians to degrees
	this.getRevolveY = k3cam_getRevolveY;
	this.getRevolveXZ = k3cam_getRevolveXZ;
	this.makeGrid = k3cam_makeGrid;
	this.approachXYZ = k3cam_approachXYZ;
	this.strafeXZ = k3cam_strafeXZ;
	this.getThetaXZ = k3cam_getThetaXZ;
	this.getThetaYZ = k3cam_getThetaYZ;
	//--

	function k3cam_strafeXZ( p, theta, d ){

		p.x += (Math.sin(theta - (0.5*Math.PI)) * d);
		p.z += (Math.cos(theta - (0.5*Math.PI)) * d);

		return p;
	}
	//--
	function k3cam_approachXYZ( p, c, dist ){
		var xd, yd, zd, d;

  		//calc current distance
  		xd = c.x - p.x;
		yd = c.y - p.y;
		zd = c.z - p.z;
		d = Math.sqrt((xd*xd)+(yd*yd)+(zd*zd));

		if(d > 0){
	  		//apply offsets
  			p.x = p.x + (xd / d * dist);
  			p.y = p.y + (yd / d * dist);
  			p.z = p.z + (zd / d * dist);
		}
		
		return p;
	}
	//--
	function k3cam_makeGrid( nx, nz, dx, dz, clr, dpth ){
		var m,g,ls,i;
					
		m = new THREE.LineBasicMaterial({color: clr});
		m.depthWrite = dpth;

		const vertices = []

		for(i=0; i <= nx; i++){
			vertices.push( new THREE.Vector3( i*dx, 0, 0 ), new THREE.Vector3( i*dx, 0, nz*dz )	);
		}
		for(i=0; i <= nz; i++){
			vertices.push( new THREE.Vector3( 0, 0, i*dz ), new THREE.Vector3( nx*dx, 0, i*dz ) );
		}

		g = new THREE.BufferGeometry().setFromPoints( vertices );

		ls = new THREE.LineSegments( g, m );
		return ls;
	}
	//--
	
	function k3cam_getRevolveXZ( p, c, angle ){
		var xd,zd,d,t;
				
		xd = p.x-c.x;
		zd = p.z-c.z;
		d = Math.sqrt((xd*xd)+(zd*zd));

		if(d === 0) t = 0.0;
		else t = Math.acos(xd/d);

		if(zd >= 0.0) t += angle;
		if(zd < 0.0) t = (2.0*Math.PI) - t + angle;

		p.x = c.x + (Math.cos(t)*d);
		p.z = c.z + (Math.sin(t)*d);
				
		return p;
	}//

	function k3cam_getRevolveY( p, c, angle ){
		var xd,yd,zd,d,d2,t,t2;

		xd = p.x-c.x;
		yd = p.y-c.y;
		zd = p.z-c.z;

		d = Math.sqrt((xd*xd)+(zd*zd));
		d2 = Math.sqrt((xd*xd)+(yd*yd)+(zd*zd));

		if(d === 0) t = 0.0;
		else t = Math.acos(xd/d);

		if(zd < 0.0) t = (2.0*Math.PI) - t;

		if(d2 === 0) t2 = 0.0;
		else t2 = Math.acos(d/d2);
				
		if(yd >= 0.0) t2 += angle;
		if(yd < 0.0) t2 = (2.0*Math.PI) - t2 + angle;
				
		p.y = c.y + (Math.sin(t2) * d2);
		p.x = c.x + (Math.cos(t) * Math.cos(t2) * d2);
		p.z = c.z + (Math.sin(t) * Math.cos(t2) * d2);

		return p;
	}//

	function k3cam_makeSkybox(tex_sky){
		let sky_geo = new THREE.BoxGeometry( 4900, 4900, 4900 );
		let matarr = [];
		matarr.push( new THREE.MeshBasicMaterial({ map: tex_sky[0], side: THREE.BackSide }));		
		matarr.push( new THREE.MeshBasicMaterial({ map: tex_sky[1], side: THREE.BackSide }));		
		matarr.push( new THREE.MeshBasicMaterial({ map: tex_sky[2], side: THREE.BackSide }));		
		matarr.push( new THREE.MeshBasicMaterial({ map: tex_sky[3], side: THREE.BackSide }));		
		matarr.push( new THREE.MeshBasicMaterial({	map: tex_sky[4], side: THREE.BackSide }));		
		matarr.push( new THREE.MeshBasicMaterial({	map: tex_sky[5], side: THREE.BackSide }));		
		
		return new THREE.Mesh( sky_geo, matarr );
	}//

	function k3cam_addPos( dx, dy, dz ){
		
		if(dx !== 0){
			this.camera.position.x += dx;
			this.lookPoint.x += dx;
		}
		if(dy !== 0){
			this.camera.position.y += dy;
			this.lookPoint.y += dy;
		}
		if(dz !== 0){
			this.camera.position.z += dz;
			this.lookPoint.z += dz;
		}	
	}//

	function k3cam_setDir( xz_deg, y_deg ){
		
		this.dirXZ = xz_deg;
		this.dirYZ = y_deg;
				
		this.lookPoint.x = this.camera.position.x + ( Math.sin(this.d2r(this.dirXZ)) * this.defLookDist );
		this.lookPoint.y = this.camera.position.y;
		this.lookPoint.z = this.camera.position.z + ( Math.cos(this.d2r(this.dirXZ)) * this.defLookDist );
		
		this.getRevolveY(this.lookPoint, this.camera.position, this.d2r(this.dirYZ));
		this.camera.lookAt(this.lookPoint);
	}//

	function k3cam_updateDir(){
	
		this.dirXZ = this.r2d(this.getThetaXZ(this.camera.position, this.lookPoint));
		this.dirYZ = this.r2d(this.getThetaYZ(this.camera.position, this.lookPoint));
	}//

	function k3cam_getThetaXZ( p, c ){
  		var xd, zd, d, t;
		
		xd = c.x - p.x;
		zd = c.z - p.z;
		d = Math.sqrt((xd*xd)+(zd*zd));
		if(d === 0) t = 0.0;
		else t = Math.acos(xd/d);

		if(zd < 0.0) t = (2.0*Math.PI) - t;
		return t;
	}//

	function k3cam_getThetaYZ( p, c ){
		var xd, yd, zd, d, d2, t;
	
		xd = c.x-p.x;
		yd = c.y-p.y;
		zd = c.z-p.z;
	
		d = Math.sqrt((xd*xd)+(zd*zd));
		d2 = Math.sqrt((yd*yd)+(d*d));

		if(d2 === 0) t = 0.0;
		else t = Math.acos(d/d2);

		if(yd < 0.0) t = -t;
		return t;
	}//

	function k3cam_mouseDown( e ){

		//update current values ( in case mouse hasnt moved yet )
		this.curMouseX = e.clientX;
		this.curMouseY = e.clientY;

		//set button state
		switch(e.button){
			case 0: this.mouseBtn0 = true; break;
			case 1: this.mouseBtn1 = true; break;
			case 2: this.mouseBtn2 = true; break;
			default: break; //keep compiler happy
		}		
	}//

	function k3cam_mouseUp( e ){

		//unset button state
		switch(e.button){
			case 0: this.mouseBtn0 = false; break;
			case 1: this.mouseBtn1 = false; break;
			case 2: this.mouseBtn2 = false; break;
			default: break; //keep compiler happy			
		}
	}//

	function k3cam_mouseMove( e ){
		
		//calc the diff between new pos and old
		this.mouseDiffX = e.clientX - this.curMouseX;
		this.mouseDiffY = e.clientY - this.curMouseY;
	
		//update current values
		this.curMouseX = e.clientX;
		this.curMouseY = e.clientY;

		if(this.mouseBtn0 && !this.posLocked){ //left button
			//set move forward / backward
			if(this.mouseDiffY > 0){
				this.approachXYZ(this.camera.position, this.lookPoint, -this.mouseDiffY*0.1);
				this.approachXYZ(this.lookPoint, this.camera.position, this.mouseDiffY*0.1);
			} else {
				this.approachXYZ(this.lookPoint, this.camera.position, this.mouseDiffY*0.1);
				this.approachXYZ(this.camera.position, this.lookPoint, -this.mouseDiffY*0.1);
			}
			//set strafe left / right
			this.strafeXZ(this.camera.position, (this.dirXZ/180.0*Math.PI), this.mouseDiffX*0.05); 
			this.strafeXZ(this.lookPoint, (this.dirXZ/180.0*Math.PI), this.mouseDiffX*0.05); 
			this.camera.lookAt(this.lookPoint);
			
		}
		else if(this.mouseBtn2 && !this.viewLocked){ //right button
			var a, mdx, mdy;
			
			mdx = this.mouseDiffX*0.1;
			mdy = -this.mouseDiffY*0.1;
			
			//set look left / right
			if(mdx !== 0){
				this.dirXZ -= mdx
				
				if(this.dirXZ > 360.0) this.dirXZ -= 360.0;
				if(this.dirXZ < 0.0) this.dirXZ += 360.0;
				
				this.lookPoint = this.getRevolveXZ(this.lookPoint, this.camera.position, mdx/180.0*Math.PI);
			}

			//set look up / down
			if(mdy > 0.0){ //looking up
				
				if (88.0 - this.dirYZ < mdy) a = 88.0 - this.dirYZ;
				else a = mdy;

				this.dirYZ += a;
				this.lookPoint = this.getRevolveY(this.lookPoint, this.camera.position, a/180.0*Math.PI);
			}
			else if(mdy < 0.0){ //looking down

				if (this.dirYZ + 88.0 < -mdy) a = -88.0 - this.dirYZ;
				else a = mdy;

				this.dirYZ += a;
				this.lookPoint = this.getRevolveY(this.lookPoint, this.camera.position, a/180.0*Math.PI);
			}
			
			//update look point once both left / right and up / down are finished
			this.camera.lookAt(this.lookPoint);
		}//end right button
	}//

	function k3cam_swipeDown_1( e ){

		e.preventDefault()
		//update current values
		this.curSwipeX_1 = e.touches[0].clientX;
		this.curSwipeY_1 = e.touches[0].clientY;
	}//

	function k3cam_swipeMove_1( e ){

		e.preventDefault()
		//calc the diff between new pos and old
		this.swipeDiffX_1 = e.touches[0].clientX - this.curSwipeX_1;
		this.swipeDiffY_1 = e.touches[0].clientY - this.curSwipeY_1;
		
		//update current values
		this.curSwipeX_1 = e.touches[0].clientX;
		this.curSwipeY_1 = e.touches[0].clientY;

		if(!this.posLocked){ //left button
			//set move forward / backward
			if(this.swipeDiffY_1 > 0){
				this.approachXYZ(this.camera.position, this.lookPoint, -this.swipeDiffY_1*0.1);
				this.approachXYZ(this.lookPoint, this.camera.position, this.swipeDiffY_1*0.1);
			} else {
				this.approachXYZ(this.lookPoint, this.camera.position, this.swipeDiffY_1*0.1);
				this.approachXYZ(this.camera.position, this.lookPoint, -this.swipeDiffY_1*0.1);
			}
			//set strafe left / right
			this.strafeXZ(this.camera.position, (this.dirXZ/180.0*Math.PI), this.swipeDiffX_1*0.05); 
			this.strafeXZ(this.lookPoint, (this.dirXZ/180.0*Math.PI), this.swipeDiffX_1*0.05); 
			this.camera.lookAt(this.lookPoint);
		}
	}//

	function k3cam_swipeDown_2( e ){

		e.preventDefault()
		//update current values
		this.curSwipeX_2 = e.touches[0].clientX;
		this.curSwipeY_2 = e.touches[0].clientY;
	}//

	function k3cam_swipeMove_2( e ){

		e.preventDefault()

		//calc the diff between new pos and old
		this.swipeDiffX_2 = e.touches[0].clientX - this.curSwipeX_2;
		this.swipeDiffY_2 = e.touches[0].clientY - this.curSwipeY_2;
		
		//update current values
		this.curSwipeX_2 = e.touches[0].clientX;
		this.curSwipeY_2 = e.touches[0].clientY;

		//move view
		if(!this.viewLocked){ 
			let a, mdx, mdy;
			
			mdx = this.swipeDiffX_2*0.2;
			mdy = -this.swipeDiffY_2*0.2;
			
			//set look left / right
			if(mdx !== 0){
				this.dirXZ -= mdx
				
				if(this.dirXZ > 360.0) this.dirXZ -= 360.0;
				if(this.dirXZ < 0.0) this.dirXZ += 360.0;
				
				this.lookPoint = this.getRevolveXZ(this.lookPoint, this.camera.position, mdx/180.0*Math.PI);
			}

			//set look up / down
			if(mdy > 0.0){ //looking up
				
				if (88.0 - this.dirYZ < mdy) a = 88.0 - this.dirYZ;
				else a = mdy;

				this.dirYZ += a;
				this.lookPoint = this.getRevolveY(this.lookPoint, this.camera.position, a/180.0*Math.PI);
			}
			else if(mdy < 0.0){ //looking down

				if (this.dirYZ + 88.0 < -mdy) a = -88.0 - this.dirYZ;
				else a = mdy;

				this.dirYZ += a;
				this.lookPoint = this.getRevolveY(this.lookPoint, this.camera.position, a/180.0*Math.PI);
			}
			
			//update look point once both left / right and up / down are finished
			this.camera.lookAt(this.lookPoint);
		}//end move view
	}//

	function k3cam_swipeDown_3( e ){

		e.preventDefault()
		//update current values
		this.curSwipeX_3 = e.touches[0].clientX;
		this.curSwipeY_3 = e.touches[0].clientY;
	}//
	
	function k3cam_swipeMove_3( e ){

		e.preventDefault()
		//calc the diff between new pos and old
		this.swipeDiffX_3 = e.touches[0].clientX - this.curSwipeX_3;
		this.swipeDiffY_3 = e.touches[0].clientY - this.curSwipeY_3;
		
		//update current values
		this.curSwipeX_3 = e.touches[0].clientX;
		this.curSwipeY_3 = e.touches[0].clientY;

		if(!this.posLocked){ //left button
			this.camera.position.y += (this.swipeDiffY_3*-0.1);
			this.lookPoint.y += (this.swipeDiffY_3*-0.1);
		}
	}//

	function k3cam_keyDown( event ){
		
		let a, rdns;			
		switch(event.code){	
			/* for arrow keys use preventDefault() so they dont scroll the page */
			case "ArrowUp": 
				event.preventDefault()
				this.approachXYZ(this.camera.position, this.lookPoint, 1.0);
				this.approachXYZ(this.lookPoint, this.camera.position, -1.0);
			break;

			case "ArrowDown": 
				event.preventDefault()			
				this.approachXYZ(this.camera.position, this.lookPoint, -1.0);
				this.approachXYZ(this.lookPoint, this.camera.position, 1.0);	
			break;

			case "ArrowLeft": 
				event.preventDefault()					
				this.strafeXZ(this.camera.position, (this.dirXZ/180.0*Math.PI), -1.0); 
				this.strafeXZ(this.lookPoint, (this.dirXZ/180.0*Math.PI), -1.0); 
				this.camera.lookAt(this.lookPoint);
			break;

			case "ArrowRight":
				event.preventDefault()					
				this.strafeXZ(this.camera.position, (this.dirXZ/180.0*Math.PI), 1.0); 
				this.strafeXZ(this.lookPoint, (this.dirXZ/180.0*Math.PI), 1.0); 
				this.camera.lookAt(this.lookPoint);
			break;

			case "KeyW": // rotates camera up
				if (88.0 - this.dirYZ < 3.0) a = 88.0 - this.dirYZ;
				else a = 3.0;
				
				this.dirYZ += a;
				this.lookPoint = this.getRevolveY(this.lookPoint, this.camera.position, a/180.0*Math.PI);
				this.camera.lookAt(this.lookPoint);
			break;			

			case "KeyS": // rotates camera down
				if(this.dirYZ + 88.0 < 3.0) a = -88.0 - this.dirYZ;
				else a = -3.0;
				
				this.dirYZ += a;
				this.lookPoint = this.getRevolveY(this.lookPoint, this.camera.position, a/180.0*Math.PI);
				this.camera.lookAt(this.lookPoint);
			break;

			case "KeyA": // rotates camera left					
				this.dirXZ += 3.0;
				if(this.dirXZ > 360.0){
					this.dirXZ -= 360.0;
				}
				rdns = -3.0/180.0*Math.PI;
				this.lookPoint = this.getRevolveXZ(this.lookPoint, this.camera.position, rdns);
				this.camera.lookAt(this.lookPoint);
			break;		

			case "KeyD": // rotates camera right
				this.dirXZ -= 3.0;
				if(this.dirXZ < 0.0){
					this.dirXZ += 360.0;
				}
				rdns = 3.0/180.0*Math.PI;
				this.lookPoint = this.getRevolveXZ(this.lookPoint, this.camera.position, rdns);					
				this.camera.lookAt(this.lookPoint);
			break;
	
			case "KeyO": // raises elevation
				this.camera.position.y += 0.2;
				this.lookPoint.y += 0.2;
			break;

			case "KeyL": // lowers elevation
				this.camera.position.y -= 0.2;
				this.lookPoint.y -= 0.2;
			break;				

			default: //keep compiler happy
			break;				
		}
	}//
}//-- end k3cam object

function k3camAutofly() //k3camAutofly object
{
	this.waypoints = [];
	this.wayspline = null;
	this.wayscroll = 0.0;
	this.waylength = null;
	this.waycube = null;
	
	//functions
	this.init = k3camAutofly_init;
	this.makePath = k3camAutofly_makePath;
	
	//methods	
	function k3camAutofly_init(){

		//original, amazing curve
		/*
		this.waypoints[0] = new THREE.Vector3(0,7,2);
		this.waypoints[1] = new THREE.Vector3(0,8,18);
		this.waypoints[2] = new THREE.Vector3(20,10,40);
		this.waypoints[3] = new THREE.Vector3(100,32,40);
		this.waypoints[4] = new THREE.Vector3(120,22,20);
		this.waypoints[5] = new THREE.Vector3(120,17,-105);
		this.waypoints[6] = new THREE.Vector3(100,15,-125);
		this.waypoints[7] = new THREE.Vector3(20,8,-125);
		this.waypoints[8] = new THREE.Vector3(0,1,-105);
		this.waypoints[9] = new THREE.Vector3(0,7,2);
*/


		//this is good
		/*
		this.waypoints[0] = new THREE.Vector3(2,7,2);
		this.waypoints[1] = new THREE.Vector3(2,9,90);
		this.waypoints[2] = new THREE.Vector3(20,15,80);
		this.waypoints[3] = new THREE.Vector3(100,32,60);
		this.waypoints[4] = new THREE.Vector3(120,22,20);
		this.waypoints[5] = new THREE.Vector3(120,17,-105);
		this.waypoints[6] = new THREE.Vector3(100,15,-125);
		this.waypoints[7] = new THREE.Vector3(20,8,-125);
		this.waypoints[8] = new THREE.Vector3(0,1,-105);
		this.waypoints[9] = new THREE.Vector3(2,7,2);
*/

		/*planet flight routine
		this.waypoints[0] = new THREE.Vector3(0,7,2);
		this.waypoints[1] = new THREE.Vector3(2,9,87);
		this.waypoints[2] = new THREE.Vector3(24,15,87);
		this.waypoints[3] = new THREE.Vector3(100,32,80);
		this.waypoints[4] = new THREE.Vector3(120,22,20);
		this.waypoints[5] = new THREE.Vector3(120,17,-105);
		this.waypoints[6] = new THREE.Vector3(100,15,-125);
		this.waypoints[7] = new THREE.Vector3(20,8,-125);
		this.waypoints[8] = new THREE.Vector3(0,1,-105);
		this.waypoints[9] = new THREE.Vector3(0,7,2);
*/

this.waypoints[0] = new THREE.Vector3(0,5,0);
this.waypoints[1] = new THREE.Vector3(0,8,18);
this.waypoints[2] = new THREE.Vector3(20,10,40);
this.waypoints[3] = new THREE.Vector3(100,32,40);
this.waypoints[4] = new THREE.Vector3(120,22,20);
this.waypoints[5] = new THREE.Vector3(120,17,-105);
this.waypoints[6] = new THREE.Vector3(100,15,-125);
this.waypoints[7] = new THREE.Vector3(20,8,-125);
this.waypoints[8] = new THREE.Vector3(0,1,-105);
this.waypoints[9] = new THREE.Vector3(0,5,0);


		//smooth out the join ?? works soso
		this.waypoints[0].x = (this.waypoints[1].x + this.waypoints[8].x) / 2
		this.waypoints[0].y = (this.waypoints[1].y + this.waypoints[8].y) / 2
		this.waypoints[0].z = (this.waypoints[1].z + this.waypoints[8].z) / 2

		this.waypoints[9].x= this.waypoints[0].x
		this.waypoints[9].y= this.waypoints[0].y
		this.waypoints[9].z= this.waypoints[0].z

		//create spline
		this.wayspline = new THREE.CatmullRomCurve3( this.waypoints );		
		var spaced_points = this.wayspline.getSpacedPoints(90);
		this.wayspline2 = new THREE.CatmullRomCurve3( spaced_points );
	}
	
	function k3camAutofly_makePath(){
		
		const g = [] //geometry
		const g2_pos = [] //vertices
		
		for(let i=0; i <= 150; i++){ // going to make 150 vertices from this spline
			let pt = this.wayspline.getPointAt(i/150);
			g[i] = new THREE.Vector3(pt.x, pt.y, pt.z );				
		}

		let m = new THREE.MeshBasicMaterial({color: 0xff0000});			
		m.side = THREE.DoubleSide;
		
		let b1 = new THREE.Object3D();
		for(let i=0; i < 150; i++){
			let g2 = new THREE.PlaneGeometry( 0.1, 0.1 );
	
			g2_pos[0] = g[i].x;
			g2_pos[1] = g[i].y + 0.1;
			g2_pos[2] = g[i].z;

			g2_pos[3] = g[i+1].x;
			g2_pos[4] = g[i+1].y + 0.1;
			g2_pos[5] = g[i+1].z;

			g2_pos[6] = g[i].x;
			g2_pos[7] = g[i].y - 0.1;
			g2_pos[8] = g[i].z;

			g2_pos[9] = g[i+1].x;
			g2_pos[10] = g[i+1].y - 0.1;
			g2_pos[11] = g[i+1].z;

			g2.setAttribute( 'position', new THREE.Float32BufferAttribute( g2_pos, 3 ) );				

			let ls = new THREE.Mesh(g2, m);
			b1.add(ls);		
		}							
	
		
		//create waypoint cubes
		let gb, msh;
		for(let i=0; i < this.waypoints.length-1; i++){

			//gb = new THREE.BoxGeometry(0.5, 0.5, 0.5);

		//	if(i !== 0){
				gb = new THREE.BoxGeometry(1.5, 1.5, 1.5);
			
			 m = new THREE.MeshBasicMaterial({color: 0xff0000});			
		
			msh = new THREE.Mesh(gb, m);
			msh.position.x = this.waypoints[i].x;
			msh.position.y = this.waypoints[i].y;
			msh.position.z = this.waypoints[i].z;
			b1.add(msh);			
	//		}
//			else {
/* 				gb = new THREE.BoxGeometry(1.5, 1.5, 1.5);
			
				m = new THREE.MeshBasicMaterial({color: 0xffff00});			
		 
			 msh = new THREE.Mesh(gb, m);
			 msh.position.x = this.waypoints[i].x;
			 msh.position.y = this.waypoints[i].y;
			 msh.position.z = this.waypoints[i].z;
			 b1.add(msh);			
	 
 */			//}
		}

		//create spline points
		for(let i=0; i < this.wayspline2.points.length; i++){
			gb = new THREE.BoxGeometry(0.5, 0.5, 0.5);
			
				m = new THREE.MeshBasicMaterial({color: 0x0000ff})
	//			m = new THREE.MeshBasicMaterial({color: 0x00ff00});			

			
			msh = new THREE.Mesh(gb, m);
			msh.position.x = this.wayspline2.points[i].x;
			msh.position.y = this.wayspline2.points[i].y;
			msh.position.z = this.wayspline2.points[i].z;
			if(i !== 0)
			b1.add(msh);			
		}

		return b1;
	}//
}//-- end k3camAutofly object

function k3lights(scene) // k3lights object
{
	this.ambLight = null;
	this.dirLight = null;
	this.camLight = null;
	
	this.init = k3lights_init;
	this.update = k3lights_update;
	
	function k3lights_init(scene){
		this.ambLight = new THREE.AmbientLight( 0x444444 );
		scene.add( this.ambLight );

		this.dirLight = new THREE.DirectionalLight( 0xAAAAAA );
		this.dirLight.position.set( 90, 100, 70 ).normalize();
		scene.add( this.dirLight );

		this.camLight = new THREE.PointLight( 0xEEBB88, 2.5, 100, 1 );
		//this.camLight = new THREE.PointLight( 0x222244, 2.5, 100, 1 );
		//this.camLight.position = super.camera.position;
		this.camLight.position.set( 10, 20, 10 );
		scene.add( this.camLight );
	}//--
	function k3lights_update(pos){
	
		//this.camLight.position.x = k3.camera.position.x;
		//this.camLight.position.y = k3.camera.position.y + 5;
		//this.camLight.position.z = k3.camera.position.z;

		this.camLight.position.x = pos.x;
		this.camLight.position.y = pos.y + 5;
		this.camLight.position.z = pos.z;
	}	
}//-- end k3lights object
