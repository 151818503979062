import { cl, gebi } from '../winglob'

import { useEffect } from "react"
import { bound } from '../winglob'
import * as THREE from 'three'

import PageTitle from "../comp/PageTitle"
import { k3cam } from "../k3/k3cam"

import controls1 from '../img/ui-icons/controls1.png'

const GlamHelp = () => {
  cl("render: GlamHelp")

  useEffect(() => {
    window.scrollTo(0, 0)

    let scene, renderer, w, h
    let container = gebi("three-container100")
    let sizer = gebi("three-sizer100")

    scene = new THREE.Scene();
    renderer = new THREE.WebGLRenderer();
    renderer.domElement.oncontextmenu = function () { return false; }
    container.appendChild(renderer.domElement);

    const k3 = new k3cam();
    k3.lights.init(scene);
    k3.posLocked = false;
    k3.viewLocked = false;
    k3.autofly.init();

    k3.addPos(-50, 0, -50);
    k3.setDir(50, 4);

    function resizeCanvas() {
      w = bound(sizer).width
      h = w / 2.0
      if (h < 300) h = 300;
      renderer.setSize(w, h);
      k3.camera.aspect = w / h;
      k3.camera.updateProjectionMatrix();
    }
    resizeCanvas()
    window.addEventListener("resize", resizeCanvas)
    window.addEventListener('mousedown', (e) => k3.mouseDown(e), false);
    window.addEventListener('mousemove', (e) => k3.mouseMove(e), false);
    window.addEventListener('mouseup', (e) => k3.mouseUp(e), false);
    window.addEventListener('keydown', (e) => k3.keyDown(e), true);
    let swipeStart1 = gebi("swipe-start1")
    swipeStart1.addEventListener("touchstart", (e) => k3.swipeDown1(e), true);
    swipeStart1.addEventListener("touchmove", (e) => k3.swipeMove1(e), true);
    let swipeStart2 = gebi("swipe-start2")
    swipeStart2.addEventListener("touchstart", (e) => k3.swipeDown2(e), true);
    swipeStart2.addEventListener("touchmove", (e) => k3.swipeMove2(e), true);
    let swipeStart3 = gebi("swipe-start3")
    swipeStart3.addEventListener("touchstart", (e) => k3.swipeDown3(e), true);
    swipeStart3.addEventListener("touchmove", (e) => k3.swipeMove3(e), true);

    function animate() {
      requestAnimationFrame(animate);
      k3.lights.update(k3.camera.position);
      renderer.render(scene, k3.camera);
    }

    //load textures, build scene, start animate
    const loadManager = new THREE.LoadingManager();
    const loader = new THREE.TextureLoader(loadManager);
    const tex_sky = [
      loader.load('./k3/skybox/1.jpg'),
      loader.load('./k3/skybox/2.jpg'),
      loader.load('./k3/skybox/3.jpg'),
      loader.load('./k3/skybox/4.jpg'),
      loader.load('./k3/skybox/5.jpg'),
      loader.load('./k3/skybox/6.jpg')
    ]

    loadManager.onLoad = () => {
      //build scene           
      scene.add(k3.makeSkybox(tex_sky));
      scene.add(k3.autofly.makePath());
      requestAnimationFrame(animate);
    };

    return () => { //useEffect
      container.removeChild(renderer.domElement);
      window.removeEventListener("resize", resizeCanvas)
      window.removeEventListener('mousedown', k3.mouseDown);
      window.removeEventListener('mousemove', k3.mouseMove);
      window.removeEventListener('mouseup', k3.mouseUp);
      window.removeEventListener('keydown', k3.keyDown);
      swipeStart1.removeEventListener("touchstart", k3.swipeDown1);
      swipeStart1.removeEventListener("touchmove", k3.swipeMove1);
      swipeStart2.removeEventListener("touchstart", k3.swipeDown2);
      swipeStart2.removeEventListener("touchmove", k3.swipeMove2);
      swipeStart3.removeEventListener("touchstart", k3.swipeDown3);
      swipeStart3.removeEventListener("touchmove", k3.swipeMove3);
    }

  }, [])


  return (
    <section className="page-section" data-desc="is-sizer" id="page-sizer" style={{ position: "relative" }}>

      <PageTitle title="Help Page" subtitle="Navigation" />

      <article className="parent-article ass">
        <div className="child-article three-sizer-container">
          <div data-desc="is-sizer" id="three-sizer100"></div>
        </div>
        <div className="child-article three-canvas-container" id="three-container100"></div>
      </article>

      <article className="parent-article flex-row ass">
        <img id="swipe-start1" src={controls1} alt="" style={{ width: "3rem", padding: "1.5rem", zIndex: 80 }} />
        <img id="swipe-start3" src={controls1} alt="" style={{ width: "3rem", padding: "1.5rem", zIndex: 80 }} />
        <img id="swipe-start2" src={controls1} alt="" style={{ width: "3rem", padding: "1.5rem", zIndex: 80 }} />
      </article>

    </section >
  )
}

export default GlamHelp