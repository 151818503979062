import {cl} from './winglob'
import {HashRouter, Routes, Route} from 'react-router-dom'

import Layout from './Layout'

import GlamHelp from './pg/GlamHelp'
import GlamPage from './pg/GlamPage'

function App() {
  cl("render: App")  
  return (
    <HashRouter>
      <Layout>
        <Routes>
          <Route>
            <Route path="/" element={<GlamPage />}></Route>
            <Route path="help" element={<GlamHelp />}></Route>
            <Route path="glam" element={<GlamPage />}></Route>
           </Route>
        </Routes>
      </Layout>    
    </HashRouter>
  )
}

export default App

