import {cl, gebi} from './winglob'
import { useEffect, useRef, useCallback, createContext, useState } from "react";
import * as THREE from "three";

import { k3cam } from "./k3/k3cam";

import AppFooter from "./AppFooter";
//import AppHeader from "./comp/AppHeader";

import spinImg from "./img/ui-icons/spinner2.svg";

export const ModalContext = createContext();

const Layout = ({ children }) => {
  cl("render: Layout")

  const refModal = useRef(null);
  const imgCounter = useRef(0);
  const refFps = useRef(0)
  const refLast = useRef(0)  
    
  const [fps, setFps] = useState(0)

  useEffect(()=>{
      let h = null

      function calcFps(){
        setFps(refFps.current/5)
        refFps.current = 0
        clearTimeout(h)
        h = setTimeout(()=>calcFps(), 5000)
      }
      h = setTimeout(()=>calcFps(), 5000)      

      return ()=>{
        clearTimeout(h)
      }
  }, [])//

  const showModal = useCallback(
    (mode = 0) => {
      refModal.current.style.visibility = "visible";
      if (mode === 0) refModal.current.style.background = "#000000";
      else if (mode === 1) refModal.current.style.background = "#00000000";
    },
    []
  )

  const hideModal = useCallback(() => {
    refModal.current.style.visibility = "hidden";
  }, [])

  const checkModal = useCallback(() => {
    imgCounter.current++;
    if (imgCounter.current === 11) hideModal();
    //console.log(imgCounter.current)
  }, [hideModal]);

  useEffect(() => {
    let scene, renderer, cam_rot, w, h, r;
    let container = gebi("three-container200");
    let sizer = gebi("app-background");

    scene = new THREE.Scene();
    renderer = new THREE.WebGLRenderer();

    renderer.domElement.oncontextmenu = function () {
      return false;
    };
    container.appendChild(renderer.domElement);

    const k3 = new k3cam();
    k3.lights.init(scene);
    k3.posLocked = true;
    k3.viewLocked = true;
    k3.autofly.init();
    k3.addPos(17, 11, -23);
    k3.setDir(50, 4);
    cam_rot = 0.0;

    //resize canvas - renderer and k3cam must be ready first
    function resizeCanvas() {
      
      r = sizer.getBoundingClientRect();
      w = r.width;
      h = r.height;
      renderer.setSize(w, h);
      k3.camera.aspect = w / h;
      k3.camera.updateProjectionMatrix();
      //animate()
    }
    resizeCanvas();
    
    window.addEventListener("pageshow", resizeCanvas);
    window.addEventListener("resize", resizeCanvas);

    
    const observer = new MutationObserver((mutationList, observer) => resizeCanvas());
    observer.observe(document.getElementById("app-main"), { attributes: true, childList: true, subtree: true, attributeFilter: ["height"] });


    function animate() {
 
      let tt = new Date().getTime()
      let msd = tt - refLast.current
      if(refLast.current === 0)
        msd = 30
      refLast.current = tt
      refFps.current++

      requestAnimationFrame(animate);
      k3.lights.update(k3.camera.position);

      //auto nav - can disable
      k3.autofly.wayscroll += 0.000005*msd
      
      if (k3.autofly.wayscroll >= 1.0) k3.autofly.wayscroll -= 1.0;
      let wayscroll2 = k3.autofly.wayscroll + 0.08;
      if (wayscroll2 >= 1.0) wayscroll2 -= 1.0;

      let pt = k3.autofly.wayspline2.getPoint(k3.autofly.wayscroll);
      let pt2 = k3.autofly.wayspline2.getPoint(wayscroll2);
      //var pt2 = new THREE.Vector3(0,0,0); //util

      k3.camera.position.x = pt.x;
      k3.camera.position.y = pt.y + 5;
      k3.camera.position.z = pt.z;

      k3.lookPoint = pt2;
      k3.camera.lookAt(k3.lookPoint);
      k3.updateDir();

      /*cam_rot -= 0.0001;*/
      //cam_rot -= 0.0008;

      if (cam_rot >= 360.0) cam_rot -= 360.0;
      k3.camera.rotation.y += cam_rot;

      k3.camera.rotation.x += cam_rot;
      k3.camera.rotation.z += cam_rot;

      //end scene
      renderer.render(scene, k3.camera);
    }

    //add texture loader here
    const loadManager = new THREE.LoadingManager();
    const loader = new THREE.TextureLoader(loadManager);

    const tex_sky = [
      loader.load("./k3/skybox/1.jpg"), //xpos
      loader.load("./k3/skybox/2.jpg"), //xneg
      loader.load("./k3/skybox/3.jpg"), //ypos
      loader.load("./k3/skybox/4.jpg"), //yneg
      loader.load("./k3/skybox/5.jpg"), //zpos
      loader.load("./k3/skybox/6.jpg"), //zneg
      
    ];

    loadManager.onLoad = () => {
      scene.add(k3.makeSkybox(tex_sky));

      requestAnimationFrame(animate);
      checkModal();
    }

    return () => {
      container.removeChild(renderer.domElement);
      window.removeEventListener("pageshow", resizeCanvas);
      window.removeEventListener("resize", resizeCanvas);
      observer.disconnect();

    };
  }, [checkModal]); //end effect

  return (
    <div id="app-background" className="app-background" data-desc="is-sizer" style={{ position: "relative" }}>
      <section ref={refModal} className="modal-wait flex-row row-top">
        <img src={spinImg} rel="preload" as="image" alt="Please wait..." tabIndex="-1"/>
      </section>

      <article className="star-bkgnd-anim-hue" style={{ position: "absolute" }}>
        <div className="three-canvas-container star-bkgnd-anim-contrast" id="three-container200"
        ></div>
      </article>

      <div id="app-layout" className="app-layout" style={{ height: "100%", overflow: "hidden" }}>
        
        
        <main id="app-main" data-desc="main app area" className="app-main abracadabra">
          <ModalContext.Provider value={{ showModal, hideModal, checkModal }}>
            {children}
          </ModalContext.Provider>
          <div style={{display:"flex", flexDirection:"column", flex:"1", justifyContent:"flex-end"}}>
            <AppFooter fps={fps} />
          </div>
        </main>

      </div>
    </div>
  );
};

export default Layout;
