import { useEffect, useRef, useCallback } from 'react'
import { cl, gebi, irnd } from '../../winglob'
import { buildShow } from './light-show'
import SvgPlay from '../../comp/SvgPlay'
import SvgStop from '../../comp/SvgStop'
//import trussImg from "./truss3.png"
import trussImg from "./ufo6c.png"
//import trussOverImg from "./truss_overlay.png"
//import pinkLightImg from "./pinklight3.png"
import pinkLightRay from "./spotlight4.png"
//import logoImg from "../../img/50cent-logo1.png"


const UfoShow = () => {
  cl("render: UfoShow")

  const lights = useRef([])
  const show = useRef() //an object with a list of steps
  const step = useRef(-1) //a step object in a show
  const stepcnt = useRef(0) //the index of the current step in the current show
  const showlist = useRef([]) //a list of show objects
  const showndx = useRef(0) // the index of the current show
  const timerRef = useRef(null)
  const cntRef = useRef()


  const doAnim = useCallback(() => {

    if (cntRef.current.showmode === 0) {
      cntRef.current.cursteps++
      if (cntRef.current.cursteps === cntRef.current.maxrnd) {

        cntRef.current.cursteps = 0
        cntRef.current.showmode = 1
      }

      //-- start random anim
      function doAnim_Random() {

        function getRndColor() {
          let _i = irnd(6) + 1 //leave yellow out of randomw show for now

          if (_i === 1) return "hue-rotate(175deg)" //white
          if (_i === 2) return "hue-rotate(230deg)" //purple1
          if (_i === 3) return "hue-rotate(190deg)" //purple2
          if (_i === 4) return "hue-rotate(90deg)" //blue1
          if (_i === 5) return "hue-rotate(125deg)" //blue2
          if (_i === 6) return "hue-rotate(70deg)" //green
          if (_i === 7) return "hue-rotate(320deg)" //yellow1
          if (_i === 8) return "hue-rotate(350deg)" //yellow2
          return "hue-rotate(350deg)"
        }

        for (let lht of lights.current) {

          lht.count--

          if (lht.count === 0) {
            lht.elem.style.opacity = "0.9"
            lht.count = (irnd(3) + 1) * 2
            lht.elem.style.filter = getRndColor()
          }
          else {
            let opac = Number(lht.elem.style.opacity)
            opac -= 0.5
            lht.elem.style.opacity = String(opac)
          }
        }
      }
      doAnim_Random()
      //-- end random anim
    }//-- end showmode 0
    else {
      //-- start show anim
      function doAnim_Show() {

        function getShowColor(_i) {
          if (_i === 10) return "hue-rotate(175deg)" //white
          if (_i === 20) return "hue-rotate(230deg)" //purple1
          if (_i === 30) return "hue-rotate(190deg)" //purple2
          if (_i === 40) return "hue-rotate(90deg)" //blue1
          if (_i === 50) return "hue-rotate(125deg)" //blue2
          if (_i === 60) return "hue-rotate(70deg)" //green
          if (_i === 70) return "hue-rotate(320deg)" //yellow1
          if (_i === 80) return "hue-rotate(350deg)" //yellow2
          return "hue-rotate(350deg)"
        }

        if (stepcnt.current === 0) {//rotate to next showstep
          if (step.current === -1) {
            step.current = 0
          }
          else step.current++
          if (step.current > show.current.length - 1) { //end of the current show

            //init next show
            showndx.current++
            if (showndx.current > showlist.current.length - 1)
              showndx.current = 0
            show.current = showlist.current[showndx.current]
            step.current = -1 //reset show step count
            cntRef.current.showmode = 0 //switch to random show
            doAnim()
            return
          }

          //update lights and stepcnt
          let d = show.current[step.current] //d is obj showStep
          stepcnt.current = d.count

          let lht = lights.current[0]
          if (d.data[0] === 1) lht.elem.style.opacity = "0.9"
          if (d.data[0] === -1) lht.elem.style.opacity = "0.0"
          lht.elem.style.filter = getShowColor(d.colors[0])

          lht = lights.current[1]
          if (d.data[1] === 1) lht.elem.style.opacity = "0.9"
          if (d.data[1] === -1) lht.elem.style.opacity = "0.0"
          lht.elem.style.filter = getShowColor(d.colors[1])

          lht = lights.current[2]
          if (d.data[2] === 1) lht.elem.style.opacity = "0.9"
          if (d.data[2] === -1) lht.elem.style.opacity = "0.0"
          lht.elem.style.filter = getShowColor(d.colors[2])

          lht = lights.current[3]
          if (d.data[3] === 1) lht.elem.style.opacity = "0.9"
          if (d.data[3] === -1) lht.elem.style.opacity = "0.0"
          lht.elem.style.filter = getShowColor(d.colors[3])

        }//end if stepcnt is 0
        stepcnt.current--
      }
      doAnim_Show()
      //-- stop show anim
    }
  }, [])//

  const startAnim = useCallback(() => {

    if (timerRef.current)
      stopAnim()
    doAnim()
    timerRef.current = setInterval(doAnim, 700)
  }, [doAnim])//


  useEffect(() => {

    class light {
      constructor() {
        this.count = 1
        this.elem = null
      }
    }

    class counters {
      constructor() {
        this.cursteps = 0 //num steps into cur show
        this.showmode = 0 //0 random 1 preset
        this.maxrnd = 4 //num steps rand show b4 preset mode
      }
    }

    cntRef.current = new counters()

    //build lights
    let lht = new light()
    lht.count = 1
    lht.elem = gebi("light1")
    lights.current[0] = lht

    lht = new light()
    lht.elem = gebi("light2")
    lht.count = 1
    lights.current[1] = lht

    lht = new light()
    lht.elem = gebi("light3")
    lht.count = 1
    lights.current[2] = lht

    lht = new light()
    lht.elem = gebi("light4")
    lht.count = 1
    lights.current[3] = lht


    showlist.current = buildShow()

    show.current = showlist.current[showndx.current]

    //startAnim()
    doAnim()

  }, [doAnim])//


  function stopAnim() {
    clearInterval(timerRef.current)
    timerRef.current = null
  }

  return (
    <>
      <section data-sesc="light show comp" className="flex-col prel"
        style={{ height: "40px", background: "black" }}>

        <img src={trussImg} alt=""
          style={{ position: "absolute", top: "-20px", left: "-160px" }} />

        {/*         <img src={pinkLightImg} alt=""
          style={{ position: "absolute", top: "-30px", left: "-160px" }} />
 */}
        <img id="light1" src={pinkLightRay} alt=""
          style={{
            position: "absolute", opacity: 0,
            top: "-390px", left: "500px", transform: "rotate(-61deg)",
            width: "190px", height: "1600px"
          }} />

        {/*         <img src={pinkLightImg} alt="" className="img-h-flip"
          style={{ position: "absolute", top: "-30px", left: "80px" }} />
 */}
        <img id="light2" src={pinkLightRay} alt=""
          style={{
            position: "absolute", opacity: 0,
            top: "-280px", left: "-670px", transform: "rotate(50deg)",
            width: "200px", height: "1600px"
          }} />

        {/*         <img src={pinkLightImg} alt=""
          style={{ position: "absolute", top: "-30px", left: "-400px" }} />
 */}


        <img id="light3" src={pinkLightRay} alt=""
          style={{
            position: "absolute", opacity: 0,
            top: "-280px", left: "380px", transform: "rotate(-49deg)",
            width: "220px", height: "1600px"
          }} />



        {/*         <img src={pinkLightImg} alt="" className="img-h-flip"
          style={{ position: "absolute", top: "-30px", left: "320px" }} />
 */}
        <img id="light4" src={pinkLightRay} alt=""
          style={{
            position: "absolute", opacity: 0,
            top: "-30px", left: "-290px", transform: "rotate(10deg)",
            width: "260px", height: "1600px"
          }} />


        {/*         <img src={trussOverImg} alt=""
          style={{ position: "absolute", top: "-60px" }} />
 */}
      </section>

      {/*       <img style={{ height: "6rem" }} src={logoImg} alt="" /> */}

      <div className="flex-row">
        <SvgPlay onClick={() => startAnim()} />
        <SvgStop onClick={() => stopAnim()} />
      </div>
    </>


  )
}

export default UfoShow