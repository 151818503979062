import React from 'react'
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import "./css/diamond.css";
import "./css/carousel.css";
import "./css/map.css";
import "./css/stars.css";
import "./css/app.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
console.log("render: Index");
