import { cl } from './winglob'

import HitCounter from './comp/HitCounter'

const AppFooter = ({ fps }) => {
  cl("render: AppFooter")

  return (
    <footer id="app-footer" className="app-footer" data-desc="is-sizer" style={{ position: "relative" }}>
      <article className="child-article flex-col trans-zero" style={{ marginBottom: 0, paddingBottom: 0 }}>
        <p style={{ textAlign: "center", maxWidth: "400px", margin: "0" }}>
          {/* This website is for entertainment only and has no affiliation with the artist. */}
          project name : <span style={{ color: "var(--text-white-blue" }}>index-cosmic-ufo</span>&nbsp;<br />
          current fps : <span style={{ color: "var(--text-white-blue" }}>{fps}</span>
        </p>
        <a href="https://www.kevmetz.com" style={{ textDecoration: "none" }}>
          <div style={{ zIndex: "80", position: "relative", marginBottom: 0 }} className="titanium kevmetz">kevmetz.com</div>
        </a>
        <HitCounter />

      </article>
    </footer >
  )
}

export default AppFooter